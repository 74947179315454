/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { SegmentTrackingService, WizardStepsService } from '@xcc-client/services';
import type { Product, ProductConfig, StepConfig, XccConfig } from '@xcc-models';
import { UidList, XccWindow } from '@xcc-models';
import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';

@Component({
  selector: 'xcc-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly ngUnsubscribe = new Subject<void>();
  public xccConfig_: XccConfig;
  activeStepIndex = 0;

  constructor(
    private cd: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly shoppingCartService: ShoppingCartService,
    private readonly segmentService: SegmentTrackingService,
    readonly wizardStepsService: WizardStepsService,
    @Inject('window') private readonly window: XccWindow,
  ) {}

  ngAfterViewInit() {
    // Fixes an ExpressionChangedAfterItHasBeenCheckedError error caused by the WizardComponent
    // setting the MatStepper to WizardStepsService on ngAfterViewInit lifecycle.
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig as XccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);

    this.wizardStepsService.indexChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectedIndex) => (this.activeStepIndex = selectedIndex));

    this.segmentService.callAnalyticsMethod('page', 'Checkout'); // Tracks the pageviewed Segment event
  }

  ngOnDestroy(): void {
    this.shoppingCartService.clear();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get wizardSteps(): StepConfig[] {
    return this.xccConfig_?.pageConfig.wizardSteps;
  }

  get isPostPurchase(): boolean {
    return this.activeStepIndex > this.wizardSteps.length - 3;
  }

  /**
   * Remove duplicate step labels and conditionally hide the steps progress component if there's
   * 2 or less labels.
   */
  get shouldHideWizardStepsProgress(): boolean {
    const labels = this.wizardSteps.map(({ label }) => label);
    return [...new Set(labels)].length < 3;
  }

  get stepperShouldHideRhs(): boolean {
    if (this.xccConfig_ == null) {
      return false;
    }
    return !!this.xccConfig_.pageConfig?.wizardSteps[this.activeStepIndex]?.hideRhs;
  }

  get stepperShouldHideShoppingCart(): boolean {
    if (this.xccConfig_ == null) {
      return false;
    }
    return this.xccConfig_.pageConfig?.wizardSteps[this.activeStepIndex]?.hideShoppingCart === true;
  }

  get stateSeal(): string {
    if (this.xccConfig_ == null) {
      return '';
    }
    return this.xccConfig_.pageConfig.rhsConfig.trustmarkConfig.sealImage;
  }

  get shouldHideRhs(): boolean {
    if (this.xccConfig_ == null) {
      return false;
    }
    const { showShoppingCart } = this.xccConfig_.pageConfig.rhsConfig;
    return showShoppingCart === false;
  }

  get shouldHideShoppingCart(): boolean {
    return this.xccConfig_.pageConfig.rhsConfig.showShoppingCart === false;
  }

  public addDefaultProductsToCart = (defaultProducts: Product[]): void => {
    for (const product of defaultProducts) {
      const isDiscount = product.uid === UidList.couponDiscount;
      const hasBadPrice = (product.customerPrice as number) >= 0;
      const shouldSkipAddingDiscount = isDiscount && hasBadPrice;
      const isProcessingFee = product.uid === UidList.processingFee;
      const hasBadFee = (product.customerPrice as number) <= 0;
      const shouldSkipAddingFee = isProcessingFee && hasBadFee;
      const shouldSkipAddingProduct =
        shouldSkipAddingDiscount || shouldSkipAddingFee || product.uid === UidList.rsaDiscount;

      if (shouldSkipAddingProduct) {
        continue;
      }

      this.shoppingCartService.addProduct(product, 1);
    }
  };

  public addMainProductToCart = (productConfig: ProductConfig): void => {
    const uid = UidList.course;
    const { productId, productTitle: label, productHint: hint, customerPrice } = productConfig;
    const mainProduct: Product = { uid, label, hint, customerPrice, productId };

    this.shoppingCartService.addProduct(mainProduct, 1);
  };

  public addMainProductToCartUsingStrikePrice = (productConfig: ProductConfig): void => {
    const uid = UidList.course;
    const { productId, productTitle: label, productHint: hint, strikePrice: customerPrice } = productConfig;
    const mainProduct: Product = { uid, label, hint, customerPrice, productId };

    this.shoppingCartService.addProduct(mainProduct, 1);
  };

  public onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.xccConfig_ = xccConfig;
    if (this.xccConfig_.productConfig.isMultiMainProductIds) {
      this.xccConfig_.productConfig.multiProducts.forEach((product) => {
        this.shoppingCartService.addProduct(product, 1);
        //Add extraproducts to cart
        product.extraproducts?.forEach((extraProduct) => {
          this.shoppingCartService.addProduct(extraProduct, 1);
        });
      });
    } else {
      const isSpecialDiscount = xccConfig.productConfig.defaultProducts.some((product: Product) => {
        return ['discount', 'rsa-discount'].includes(product.uid);
      });

      if (isSpecialDiscount) {
        this.addMainProductToCartUsingStrikePrice(xccConfig.productConfig);
      } else {
        this.addMainProductToCart(xccConfig.productConfig);
      }
      this.addDefaultProductsToCart(xccConfig.productConfig.defaultProducts);
    }
  };
}
