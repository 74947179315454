import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { XgritPurchaseService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/xgrit-purchase.service';

/**
 * Directive to handle duplicate product purchases.
 *
 * This directive listens to the `isDupePurchase` observable from the `XgritPurchaseService`.
 * If a duplicate purchase is detected, it hides the associated element. Otherwise, it shows the element.
 *
 * @selector [xccDupeProduct]
 */
@Directive({
  selector: '[xccDupeProduct]',
})
export class DupeProductDirective {
  constructor(
    private readonly xgritPurchaseService: XgritPurchaseService,
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {
    this.xgritPurchaseService.isDupePurchase.subscribe((isDupePurchase) => {
      if (isDupePurchase) {
        this.hideElement();
      } else {
        this.showElement();
      }
    });
  }

  /**
   * Hides the associated HTML element by adding the 'hidden' class to it.
   * Utilizes Angular's Renderer2 to safely manipulate the DOM.
   *
   * @private
   */
  private hideElement(): void {
    this.renderer?.addClass(this.el?.nativeElement, 'hidden');
  }

  /**
   * Displays the element by removing the 'hidden' class if it is present.
   * This method checks if the element's class list contains the 'hidden' class,
   * and if so, it uses the renderer to remove the 'hidden' class, making the element visible.
   *
   * @private
   */
  private showElement(): void {
    if (this.el?.nativeElement?.classList?.contains('hidden')) {
      this.renderer?.removeClass(this.el.nativeElement, 'hidden');
    }
  }
}
