import { Brand } from '@xcc-models';

export interface ProductPerBrand {
  brand: string;
  productId: string;
}

export interface StatesDictionary {
  name: string;
  abbreviation: string;
  productsPerBrand: ProductPerBrand[];
}

export const usStates: StatesDictionary[] = [
  {
    name: 'Alaska',
    abbreviation: 'AK',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'f72UtnfNQ1AsfK0d',
      }
    ]
  },
  {
    name: 'Alabama',
    abbreviation: 'AL',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'W81twjzQXDW6Fw8r',
      }
    ]
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'fo0lLF35YvSEdeQC',
      }
    ]
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'BgofPJZkArm5Cbzk',
      }
    ]
  },
  {
    name: 'California',
    abbreviation: 'CA',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: '5mqQjXYHdTWDDYoj',
      }
    ]
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'tfP0IhE0O4jvwVsD',
      }
    ]
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'gRTealBmUwqLLxnt',
      }
    ]
  },
  {
    name: 'District of Columbia',
    abbreviation: 'DC',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'NSTrIWmhZGgWg5mP',
      }
    ]
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'azmkD3Cn7HmVOYH1',
      }
    ]
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'PwEWlOwcxDHRRap2',
      }
    ]
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'BDObD4ddioTpMe88',
      }
    ]
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'Vpwt5PGgITaVf6EN',
      }
    ]
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: '6YQjoAWGgQD2PHaV',
      }
    ]
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'O8rqYrBFKSDUBCGb',
      }
    ]
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'DrNWwJSwsWxPMsQG',
      }
    ]
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'pEOkBeFT7CdqGqJO',
      }
    ]
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'SylKSR7uhjB6CMMD',
      }
    ]
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'QmuL9dDDgJtAV6my',
      }
    ]
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: '5mS2sPXcr8rTeX3m',
      }
    ]
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'MqVATwKWnwTzOlBT',
      }
    ]
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: '9LdCSgwdJEFAbThm',
      }
    ]
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'P2EwaQQtBKl4uKgC',
      }
    ]
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'hkC7dND5OLOt2EVn',
      }
    ]
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'lGMMuCNLsC9AASYF',
      }
    ]
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'avywstjs1k2CueTt',
      }
    ]
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'PcnCM09qE1OSl9ka',
      }
    ]
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'NJSswMfnFrGryuNs',
      }
    ]
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'dlwcUGAxzjEQSv3h',
      }
    ]
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'KvWOdtpFcIZ9Bma9',
      }
    ]
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'Bfhne8PBQOaKvSde',
      }
    ]
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'xCVkxmaWaowiQlZR',
      }
    ]
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'KYV5JuP4TMTCYeCH',
      }
    ]
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'UVkLCmqruNcxiFVO',
      }
    ]
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'n2qKIXrrNlERZBWV',
      }
    ]
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'vAaNLJiV5adUu9fO',
      }
    ]
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'GkonTogBIpEf3eSn',
      }
    ]
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'CMpbKfXnUWcaflBE',
      }
    ]
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'IGPgd07D9sbRFItI',
      }
    ]
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'VtE2SR1EFBQBvnmb',
      }
    ]
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'cDSZBJ6EFVRgqEN8',
      }
    ]
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'hEkZZdGlrEn73QIw',
      }
    ]
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'mR2x3RGpBKY38O6U',
      }
    ]
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'kSEeHqLjRrFrCJ5H',
      }
    ]
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: '4ZMOLDU9WwfcpidB',
      }
    ]
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'CqvwNMoj9NacaPpE',
      }
    ]
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'kmEuf3i5RlyDSMth',
      }
    ]
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'COufZ6KxWXwAT3dZ',
      }
    ]
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'RE3JVUBRcyj58kvZ',
      }
    ]
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: '2B9Sa0zyFGvm3SRN',
      }
    ]
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'kka7X1HYbHvNQz1o',
      }
    ]
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'F8GFuBT7hDmrVsoq',
      }
    ]
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: '7LBhBjBXO2Qc0FmI',
      }
    ]
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: 'GcYGcwAMLPYlHfZR',
      }
    ]
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    productsPerBrand: [
      {
        brand: Brand.AARP,
        productId: '3jnJxZuGvhbGqHZv',
      }
    ]
  },
];
