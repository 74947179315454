import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DupeProductDirective } from './dupe-product.directive';

@NgModule({
  declarations: [DupeProductDirective],
  imports: [CommonModule],
  exports: [DupeProductDirective],
})
export class DupeProductModule {}
