import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RsaOfferService, XccMaterialModule } from '@xcc-client/services';
import { DupeProductModule } from '@xcc-client/shared/directives/dupe-product/dupe-product.module';
import { ComponentsModule } from 'apps/xcc-client/src/shared/components/components.module';
import { RsaOfferPanelComponent } from './rsa-offer-panel.component';

@NgModule({
  declarations: [RsaOfferPanelComponent],
  imports: [CommonModule, XccMaterialModule, ComponentsModule, DupeProductModule],
  providers: [RsaOfferService],
})
export class RsaOfferPanelModule {}
