import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { XccEnvironment } from '@xcc-client/services';
import type { AddOn, AddOnConfig, Product, XccConfig } from '@xcc-models';
import { UidList } from '@xcc-models';
import type { AddOnEmitted } from '../shared/emmiter';
import { UpsellAddOnsService } from './upsell-addons.service';

@Component({
  selector: 'xcc-upsell-addons',
  templateUrl: './upsell-addons.component.html',
  styleUrls: ['./upsell-addons.component.scss'],
})
export class UpsellAddOnsComponent implements OnInit, OnDestroy {
  hasCdiFlag: boolean;
  hasBundleCoupon: boolean;
  bundleWithDiscount: AddOn;
  recommendedAddOns: AddOn[] = [];
  isRsaOfferCheckboxSelected: boolean;
  isBundleSelected: boolean;
  xccConfig: XccConfig;

  private readonly ngUnsubscribe = new Subject<void>();
  private toggleableAddOn: Product;
  private addOnConfig_: AddOnConfig;
  private activeAddOns: AddOnEmitted[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly addOnsService: UpsellAddOnsService,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
  ) {
    this.hasCdiFlag =
      this.route.snapshot.queryParamMap.get('cdiFlag') === '0' ||
      this.route.snapshot.queryParamMap.get('threeStep') === 'false';
    this.hasBundleCoupon = this.route.snapshot.queryParamMap.get('bundleCoupon') !== null;
  }

  get displayStrikethrough(): boolean {
    return this.xccConfig.pageConfig.displayStrikethrough;
  }

  get rsaOfferCheckboxAddOn(): AddOn | undefined {
    const rsaAddOn = this.addOnConfig_.addOns.find((addOn: AddOn) => addOn.uid === UidList.rsaOfferCheckbox);
    if (!rsaAddOn) {
      return undefined;
    }
    const rsaConfig = this.xccConfig.productConfig.rsaConfig;
    if (rsaAddOn.subtitle === undefined && rsaConfig.subtitle !== undefined) {
      rsaAddOn.subtitle = rsaConfig.subtitle;
    }
    if (rsaAddOn.title === undefined && rsaConfig.title !== undefined) {
      rsaAddOn.title = rsaConfig.title;
    }
    if (rsaAddOn.data === undefined && rsaConfig.data !== undefined) {
      rsaAddOn.data = rsaConfig.data;
    } else if (rsaAddOn.data.bullets === undefined && rsaConfig.data.bullets !== undefined) {
      rsaAddOn.data.bullets = rsaConfig.data.bullets;
    }
    return rsaAddOn;
  }

  get hasContent(): boolean {
    if (this.hasRsaOfferCheckboxAddOn) {
      return true;
    }
    if (this.hasNonPreselectedBundle) {
      return true;
    }
    return this.recommendedAddOns.length > 0;
  }

  get hasRsaOfferCheckboxAddOn(): boolean {
    return this.addOnConfig_.addOns.find((addOn: AddOn) => addOn.uid === UidList.rsaOfferCheckbox) !== undefined;
  }

  get hasNonPreselectedBundle(): boolean {
    return this.courseBundleAddOn && !this.courseBundleAddOn.preCheck;
  }

  get courseBundleAddOn(): AddOn {
    const courseBundle = this.addOnConfig_.addOns.find((addOn: AddOn) => addOn.uid === UidList.courseBundle);
    /**With cdiFlag and bundleCoupon the bundle should have the discount applied*/
    if (this.bundleWithDiscount && this.hasCdiFlag) {
      this.bundleWithDiscount.data = courseBundle.data;
      this.bundleWithDiscount.togglesAddOns = courseBundle.togglesAddOns;
      this.bundleWithDiscount.replaceCartMainProduct = courseBundle.replaceCartMainProduct;
      return this.bundleWithDiscount;
    }
    return courseBundle;
  }

  get addOnConfig(): AddOnConfig {
    return this.addOnConfig_;
  }

  get showAudio(): boolean {
    const vidAddOn = this.addOnConfig_.addOns.find((addOn: AddOn) => addOn.uid === UidList.video);
    if (vidAddOn) {
      return this.findActiveAddOnIdx(vidAddOn.uid) !== -1;
    }
    return false;
  }

  get showVideo(): boolean {
    const audAddOn = this.addOnConfig_.addOns.find((addOn: AddOn) => addOn.uid === UidList.audio);
    if (audAddOn) {
      return this.findActiveAddOnIdx(audAddOn.uid) !== -1;
    }
    return false;
  }

  ngOnInit(): void {
    this.addOnsService.toggleableProduct
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.toggleableAddOn = data));

    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);

    this.addOnsService.bundleWithDiscountAddOn
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((bundle) => (this.bundleWithDiscount = bundle));

    if (this.hasBundleCoupon) {
      this.addOnsService.fetchBundleWithCoupon(this.route.snapshot.data.xccConfig);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.addOnConfig_ = xccConfig.productConfig.addOnConfig;
    this.xccConfig = xccConfig;
    const excludedUids = [UidList.rsaOfferCheckbox, UidList.courseBundle];

    this.recommendedAddOns = this.addOnConfig_.addOns.filter((addOn: AddOn) => {
      return !excludedUids.includes(addOn.uid);
    });

    this.addOnsService.originalDiscountProduct = xccConfig.productConfig.defaultProducts.find((product: Product) =>
      [UidList.rsaDiscount, UidList.couponDiscount].includes(product.uid),
    );
  };

  findActiveAddOnIdx(uid: UidList): number {
    return this.activeAddOns.findIndex((addOn: AddOnEmitted) => addOn.addOn.uid === uid);
  }

  handleAddOnSelection(emmission: AddOnEmitted): void {
    const { addOn, isSelected } = emmission;
    // If addOn is in the active list, remove it from the list and vice versa.
    this.updateActiveList(emmission);

    /**
     * If it's an uid of rsaOfferCheckbox, don't add anything to the cart. Just Let the <xcc-ids-rsa-checkbox-offer> component know if checkbox
     * is selected by updating the isRsaOfferCheckboxSelected variable.
     */
    if (this.isRsaOfferCheckbox(addOn.uid)) {
      this.isRsaOfferCheckboxSelected = isSelected;
      return;
    }
    if (addOn.uid === UidList.courseBundle) {
      this.isBundleSelected = isSelected;
    }
    /**
     * In case video and audio are both available the user can't select both at the same time,
     * if user selects video the audio add on should be removed from the cart.
     */
    const vidIdx = this.findActiveAddOnIdx(UidList.video);
    const audIdx = this.findActiveAddOnIdx(UidList.audio);
    if (
      (addOn.uid === UidList.audio && emmission.isSelected === true && vidIdx !== -1) ||
      (addOn.uid === UidList.video && emmission.isSelected === true && audIdx !== -1)
    ) {
      this.toggleAudioVideoAddOn(addOn.uid);
    }
    this.addOnsService.handleSelectionChange(addOn, isSelected, this.toggleableAddOn);
  }

  isRsaOfferCheckbox(uid: UidList): boolean {
    return uid === UidList.rsaOfferCheckbox;
  }

  toggleAudioVideoAddOn(selectedAddOnUid: UidList) {
    let targetAddOn = this.recommendedAddOns.find((addOn: AddOn) => addOn.uid === UidList.video);
    if (selectedAddOnUid === 'video') {
      targetAddOn = this.recommendedAddOns.find((addOn: AddOn) => addOn.uid === UidList.audio);
    }
    this.updateActiveList({ addOn: targetAddOn, isSelected: false });
    this.addOnsService.handleSelectionChange(targetAddOn, false);
  }

  private updateActiveList(emmission: AddOnEmitted) {
    if (this.findActiveAddOnIdx(emmission.addOn.uid) !== -1 && emmission.isSelected === false) {
      this.activeAddOns.splice(this.findActiveAddOnIdx(emmission.addOn.uid), 1);
    }
    if (this.findActiveAddOnIdx(emmission.addOn.uid) === -1 && emmission.isSelected === true) {
      this.activeAddOns.push(emmission);
    }
  }
}
