<xcc-card-container class="mt-6 block" xccDupeProduct>
  <h3 class="panel-title xcc-header-spacing" header>Additional Notes</h3>
  <section content>
    <ng-container *ngIf="data.disclaimerItems.length === 1">
      <p class="p-small" *ngFor="let point of data.disclaimerItems">
        {{ point.text }}
        <a target="_blank" *ngIf="point.link" class="cta-button-tertiary button-sm" [href]="point.link">{{
          point.label
        }}</a>
        <xcc-dialog *ngIf="point.showDialog" data-test="view-terms-trigger" dialogCTA="Learn More"></xcc-dialog></p
    ></ng-container>
    <ng-container *ngIf="data.disclaimerItems.length > 1">
      <ul>
        <li class="p-small" *ngFor="let point of data.disclaimerItems">
          {{ point.text }}
          <a target="_blank" *ngIf="point.link" class="cta-button-tertiary button-sm" [href]="point.link">{{
            point.label
          }}</a>
          <xcc-dialog *ngIf="point.showDialog" data-test="view-terms-trigger" dialogCTA="Learn More"></xcc-dialog>
        </li>
      </ul>
    </ng-container></section
></xcc-card-container>
