/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Inject, Injectable } from '@angular/core';
import { XccEnvironment } from '@xcc-client/services';
import type { AnalyticMethod, AnalyticTrackName, IdentifyParams, SegmentParamsObject } from '@xcc-models';
import { XccWindow } from '@xcc-models';
import * as Bowser from 'bowser';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SegmentTrackingService {
  private segmentParamsObject_: SegmentParamsObject;

  constructor(
    @Inject('window') private readonly window: XccWindow,
    @Inject('xccEnv') private readonly xccEnv: XccEnvironment,
  ) {}

  /**
   * Calls a Segment Analytics method
   * @param method Analytics method to be used
   * @param trackName Name of the action/object being tracked
   * @param params Extra data for the tracked method
   */
  callAnalyticsMethod(
    method: AnalyticMethod,
    trackName: AnalyticTrackName,
    params?: SegmentParamsObject,
    traits: any = {},
  ): Observable<any> {
    if (this.xccEnv.adobeAnalytics && this.xccEnv.adobeAnalytics.trim() !== '') {
      return;
    }

    if (!this.window.analytics) {
      console.error(`window.analytics has not be defined. Unable to make track call to ${trackName}.`);
      return;
    }

    if (params) params.version = 'xcc-upgrade';

    return of(this.window.analytics[method](trackName, params, traits));
  }

  /**
   * Calls Segment's Identify method with an Id and specified params
   */
  callIdentifyMethod(id: string, { created_date, email, first_name }): void {
    if (this.xccEnv.adobeAnalytics && this.xccEnv.adobeAnalytics.trim() !== '') {
      return;
    }

    if (!this.window.analytics) {
      console.error(`window.analytics has not be defined. Unable to make Segment identify request.`);
      return;
    }

    const bowser = Bowser.getParser(window.navigator.userAgent);
    const operating_system = `${bowser.getOSName()} ${bowser.getOSVersion()}`;

    const identifyParams: IdentifyParams = {
      browser: bowser.getBrowser().name,
      browser_version: bowser.getBrowser().version,
      cookies_enabled: navigator.cookieEnabled,
      created_date: created_date as string,
      email: email as string,
      first_name: first_name as string,
      operating_system,
      signup_source: 'Web',
      user_type: 'student',
      version: 'xcc-upgrade',
    };

    this.window.analytics['identify'](id, identifyParams);
  }

  set segmentParamsObject(data: SegmentParamsObject) {
    this.segmentParamsObject_ = data;
  }

  get segmentParamsObject(): SegmentParamsObject {
    return this.segmentParamsObject_;
  }
}
