<xcc-card-container *ngIf="hasContent" xccDupeProduct>
  <h3 class="!mb-4" header>Add-Ons</h3>
  <section content>
    <xcc-rsa-checkbox-offer
      *ngIf="hasRsaOfferCheckboxAddOn"
      [isSelected]="isRsaOfferCheckboxSelected"
      [addon]="rsaOfferCheckboxAddOn"
      [displayStrikethrough]="displayStrikethrough"
      (addOnSelected)="handleAddOnSelection($event)"
      class="mb-4"
    ></xcc-rsa-checkbox-offer>
    <xcc-upsell-bundle
      *ngIf="hasNonPreselectedBundle"
      [isSelected]="isBundleSelected"
      [addon]="courseBundleAddOn"
      (addOnSelected)="handleAddOnSelection($event)"
      class="mb-4"
    >
    </xcc-upsell-bundle>
    <xcc-addon-item
      class="block"
      [addon]="addon"
      [displayStrikethrough]="displayStrikethrough"
      [isSelected]="findActiveAddOnIdx(addon.uid) > -1"
      (addOnSelected)="handleAddOnSelection($event)"
      [class.hidden]="addon.uid === 'audio' && this.showAudio"
      [class.mb-4]="!last"
      *ngFor="let addon of recommendedAddOns; let last = last"
    ></xcc-addon-item>
  </section>
</xcc-card-container>
