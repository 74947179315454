<xcc-card-container xccDupeProduct>
  <form [formGroup]="formGroup" id="xcc-your-course-form" content>
    <h3 class="panel-title xcc-header-spacing">{{ panelTitle }}</h3>
    <xcc-aarp-state-selector [data]="data.stateSelector"></xcc-aarp-state-selector>
    <xcc-aarp-uhc-check *ngIf="uhclink"></xcc-aarp-uhc-check>
    <ng-container *ngIf="!isFreePayment">
      <xcc-aarp-member *ngIf="!uhclink" [data]="aarpMember" [xccConfig]="xccConfig"></xcc-aarp-member>
    </ng-container>
    <xcc-course-history
      *ngIf="courseHistory"
      [data]="courseHistory"
      [showDialog]="courseHistoryDialog"
    ></xcc-course-history>
  </form>
</xcc-card-container>
