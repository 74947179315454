import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DupeProductModule } from '@xcc-client/shared/directives/dupe-product/dupe-product.module';
import { XccDialogModule } from '../xcc-dialog/xcc-dialog.module';
import { XccUnapprovedNoteComponent } from './xcc-unapproved-note/xcc-unapproved-note.component';

@NgModule({
  declarations: [XccUnapprovedNoteComponent],
  imports: [CommonModule, FontAwesomeModule, XccDialogModule, DupeProductModule],
})
export class XccUnapprovedNoteModule {}
