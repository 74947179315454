import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DupeProductModule } from '@xcc-client/shared/directives/dupe-product/dupe-product.module';
import { ComponentsModule } from '../components.module';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';
import { XccStudentAccountPanelComponent } from './xcc-student-account-panel/xcc-student-account-panel.component';
import { XccStudentAccountPanelService } from './xcc-student-account-panel/xcc-student-account-panel.service';

@NgModule({
  declarations: [XccStudentAccountPanelComponent],
  imports: [CommonModule, ReactiveFormsModule, ComponentsModule, DupeProductModule, ErrorMessagesModule],
  providers: [XccStudentAccountPanelService],
})
export class XccStudentAccountPanelModule {}
