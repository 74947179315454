import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccountErrorMessageComponent } from './account-error-message/account-error-message.component';

@NgModule({
  declarations: [AccountErrorMessageComponent],
  exports: [AccountErrorMessageComponent],
  imports: [CommonModule, FontAwesomeModule],
})
export class ErrorMessagesModule {}
