import { Injectable } from '@angular/core';
import type { Stripe } from '@stripe/stripe-js';
import { RsaClaimStatusService, StripeJsService } from '@xcc-client/services';
import { ShoppingCartService } from '@xcc-client/shared/components/shopping-cart/shopping-cart.service';

@Injectable()
export class PaymentStripeService {
  private stripe: Stripe;
  private totalPriceDollars_: number;
  private stripeCustomerId_: string;
  private emailAddress_: string;
  private paymentIntentId_: string;
  errorMessage: string;
  productDescription_: string;
  isCdiClaimed: string;

  constructor(
    stripeFactory: StripeJsService,
    private readonly shoppingCartService: ShoppingCartService,
    private readonly rsaClaimStatusService: RsaClaimStatusService,
  ) {
    stripeFactory.stripe.subscribe(this.onStripeReady);
    this.shoppingCartService.totalPriceDollarsChanged.subscribe(this.onTotalPriceDollarsChanged);
    this.rsaClaimStatusService.isCdiLoadedAndClaimed.subscribe((value: boolean) => {
      this.isCdiClaimed = value.toString();
    });
  }

  setProductDescription = (product: string) => {
    this.productDescription_ = product;
  };

  private onStripeReady = (stripe: Stripe): void => {
    this.stripe = stripe;
  };

  private onTotalPriceDollarsChanged = (totalPriceDollars: number): void => {
    this.totalPriceDollars_ = totalPriceDollars;
  };

  get stripeCustomerId(): string {
    return this.stripeCustomerId_;
  }

  get emailAddress(): string {
    return this.emailAddress_;
  }

  get paymentIntentId(): string {
    return this.paymentIntentId_;
  }

  get productDescription(): string {
    return this.productDescription_;
  }

  get totalPriceDollars(): number {
    return this.totalPriceDollars_;
  }
}
