import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlModule } from '@dec/shared/safe-html/safe-html.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DupeProductModule } from '@xcc-client/shared/directives/dupe-product/dupe-product.module';
import { environment } from 'apps/xcc-client/src/environments/environment';
import { RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service } from 'ng-recaptcha';
import { ComponentsModule } from '../components.module';
import { XccDialogModule } from '../xcc-dialog/xcc-dialog.module';
import { PaymentContinueService } from './xcc-continue-panel/payment-continue.service';
import { PaymentStripeService } from './xcc-continue-panel/payment-stripe.service';
import { ReCaptchaTokenService } from './xcc-continue-panel/recaptcha.service';
import { XccContinuePanelComponent } from './xcc-continue-panel/xcc-continue-panel.component';
import { XccContinuePanelService } from './xcc-continue-panel/xcc-continue-panel.service';

@NgModule({
  declarations: [XccContinuePanelComponent],
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule, ComponentsModule, XccDialogModule, SafeHtmlModule, DupeProductModule],
  providers: [
    XccContinuePanelService,
    PaymentContinueService,
    PaymentStripeService,
    ReCaptchaV3Service,
    ReCaptchaTokenService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey,
    },
  ],
})
export class XccContinuePanelModule {}
