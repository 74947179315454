/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {
  IterableSubscribeService,
  SegmentTrackingService,
  XccEnvironment,
  XgritApiService,
} from '@xcc-client/services';
import { PaymentContinueService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/payment-continue.service';
import type { SuccessResponse, XccConfig, XgritQueryParams } from '@xcc-models';
import { Brand, CompanyName } from '@xcc-models';
import type { Observable } from 'rxjs';
import { Subject, combineLatest, map, take, takeUntil } from 'rxjs';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { XccParentAccountPanelService } from './xcc-parent-account-panel.service';

import { faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { XgritPurchaseService } from '../../xcc-continue-panel/xcc-continue-panel/xgrit-purchase.service';
@Component({
  selector: 'xcc-parent-account-panel',
  templateUrl: './xcc-parent-account-panel.component.html',
})
export class XccParentAccountPanelComponent implements OnInit, OnDestroy {
  parentText = "Wait, I'm a parent!";
  studentText = "Nevermind, I'm a student";
  toogleText = this.parentText;
  iAmParent = false;
  faInfoCircle = faInfoCircle;
  faArrowRight = faArrowRight;
  readonly xccConfig: Observable<XccConfig>;
  private readonly ngUnsubscribe = new Subject<void>();
  private xccConfig_: XccConfig;
  public emailInfoMessage: string;
  private lastStudenEmail: string;
  private lastParentEmail: string;
  userExistInfoText =
    'Woo Hoo! You already have an account with us. This course will be added to your existing account';
  private couponsAdded: string[] = [];
  private productsAdded: string[] = [];
  public dupCourseMessage: string;

  constructor(
    readonly parentAccountPanelService: XccParentAccountPanelService,
    private readonly route: ActivatedRoute,
    private readonly iterableService: IterableSubscribeService,
    private readonly paymentContinueService: PaymentContinueService,
    private readonly xgritApiService: XgritApiService,
    private readonly cartService: ShoppingCartService,
    private readonly segmentService: SegmentTrackingService,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly xgritPurchaseService: XgritPurchaseService,
  ) {
    this.xccConfig = this.route.data.pipe(map((data: Data) => data.xccConfig as XccConfig));
    this.parentAccountPanelService.useParent.subscribe((status) => (this.iAmParent = status));
    this.paymentContinueService.setUseParent(true);

    this.emailInfoMessage = `Your confidentiality is important to us so you will only receive information directly from ${this.setBrandName(
      this.xccEnv.brand.toUpperCase(),
    )}. Don't find the information helpful? No worries, you can unsubscribe at anytime.`;

    this.xgritPurchaseService.isDupePurchase.subscribe((isDupe) => {
      this.dupCourseMessage = isDupe ? this.xgritPurchaseService.overrideDupErrorMessage() : '';
    });
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig as XccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);

    this.route.queryParams.subscribe((queryParams: XgritQueryParams) => {
      if (queryParams.parent === 'true') {
        this.formGroup.get('role')?.setValue('parent');
        this.onRoleChange(null);
      }
    });

    combineLatest([
      this.cartService.conditionalCouponDiscounts$,
      this.cartService.nonConditionalCouponDiscounts$,
      this.cartService.bundleCouponDiscount$,
      this.cartService.additionalCouponDiscount$,
    ]).subscribe(([conditional, nonConditional, bundle, additional]) => {
      this.couponsAdded = [...conditional, ...nonConditional, ...(bundle ? [bundle] : []), ...additional];
    });

    this.cartService.productsArray.subscribe((products) => {
      this.productsAdded = products
        .filter((product) => product.productId !== undefined)
        .map((product) => product.productId);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.formGroup.reset();
  }

  onRoleChange(event: Event): void {
    this.parentAccountPanelService.clearPassword();
    this.iAmParent = !this.iAmParent;
    this.toogleText = this.iAmParent ? this.studentText : this.parentText;
    this.parentAccountPanelService.setUseParent(this.iAmParent);
    this.parentAccountPanelService.updateFormForUserExist(false, this.iAmParent);

    this.onBlurStudentField();
  }

  /**
   * Handles the onBlur event for the parent field.
   * If the teacherEmail field is valid, subscribes the user to the Iterable service.
   * @returns {void}
   */
  onBlurParentField = (): void => {
    const name: string = (this.formGroup.get('parentFirstName')?.value as string)
      ? (this.formGroup.get('parentFirstName')?.value as string).trim()
      : '';
    const teacherEmail = this.formGroup.get('teacherEmail');
    /**
     * Check if the student email and parent email is not
     * duplicated, if the parent email is duplicated, set
     * the teacherEmail field to invalid and show error message.
     */
    this.parentAccountPanelService.emailIsNotDuplicated(this.formGroup);

    if (teacherEmail.valid) {
      this.abandonedCartEvents(teacherEmail.value as string, name, true);
      if (this.lastParentEmail !== teacherEmail.value && this.iAmParent) {
        this.checkIfUsersExists(teacherEmail.value as string);
        this.lastParentEmail = teacherEmail.value as string;
      }
    }
  };

  /**
   * Handles the onBlur event for the student field.
   * If the studentEmail field is valid, subscribes the user to the Iterable service.
   * @returns {void}
   */
  onBlurStudentField = (): void => {
    const name: string = (this.formGroup.get('studentName')?.value as string)
      ? (this.formGroup.get('studentName')?.value as string).trim()
      : '';
    const emailField = this.formGroup.get('email');
    if (emailField.valid) {
      this.abandonedCartEvents(emailField.value as string, name, false);
    }
  };

  onBlurStudentEmail = () => {
    const name: string = (this.formGroup.get('studentName').value as string)
      ? (this.formGroup.get('studentName').value as string).trim()
      : '';
    const emailField = this.formGroup.get('email');
    this.parentAccountPanelService.emailIsNotDuplicated(this.formGroup);

    if (emailField.value !== this.lastStudenEmail) {
      this.xgritPurchaseService.isDupePurchase.next(false);
    }

    if (emailField.valid) {
      this.abandonedCartEvents(emailField.value as string, name, false);
      if (this.lastStudenEmail !== emailField.value && !this.iAmParent) {
        this.checkIfUsersExists(emailField.value as string);
        this.lastStudenEmail = emailField.value as string;
      }
    }
  };

  checkIfUsersExists = (username: string) => {
    const params = { username, brandId: this.xccEnv.brand.toUpperCase() };
    this.xgritApiService.verifyEmail(params).subscribe((response: SuccessResponse) => {
      this.parentAccountPanelService.updateFormForUserExist(response?.data?.verifyEmail as boolean, this.iAmParent);
    });
  };

  get webAppUrl(): string {
    return this.xccEnv.xgritWebAppUrl;
  }

  get userExists(): boolean {
    return this.parentAccountPanelService.userExists;
  }

  get formGroup(): FormGroup {
    return this.parentAccountPanelService.formGroup;
  }

  get showError(): Observable<boolean> {
    return this.parentAccountPanelService.showError;
  }

  get passwordNotMatchMessage(): string {
    return this.xccEnv.passwordConfig.passwordNotMatchMessage;
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.xccConfig_ = xccConfig;
  };

  private setBrandName = (brand: string): CompanyName => {
    const brandMap = {
      [Brand.IDS]: CompanyName.IDS,
      [Brand.DEC]: CompanyName.DEC,
      [Brand.AA]: CompanyName.AA,
      [Brand.ACE]: CompanyName.ACE,
    };
    return (brandMap[brand] as CompanyName) || CompanyName.ACE;
  };

  abandonedCartEvents(email: string, name: string, isParent: boolean): void {
    const fields: ParentPurchasePayload = {
      name,
      email: email,
      courseState: this.xccConfig_.productConfig.state,
      coupons: this.couponsAdded,
      products: this.productsAdded,
      productId: this.xccConfig_.productConfig.productId,
      productTitle: this.xccConfig_.productConfig.productTitle,
      customerPrice: this.xccConfig_.productConfig.customerPrice,
      strikePrice: this.xccConfig_.productConfig.strikePrice,
      segment: this.xccConfig_.productConfig.segment,
      isParent: isParent,
    };
    this.iterableService.subscribeUser(fields).pipe(take(1)).subscribe();
    this.segmentService.callIdentifyMethod(null, {
      created_date: new Date().toISOString(),
      email: email,
      first_name: name,
    });
  }
}

interface ParentPurchasePayload {
  name: string;
  email: string;
  courseState: string;
  teacherEmail?: string;
  coupons: string[];
  products: string[];
  productId: string;
  productTitle: string;
  customerPrice: number;
  strikePrice: number;
  segment: string;
  isParent: boolean;
}
