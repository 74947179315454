/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  faArrowRight,
  faCheckCircle,
  faInfoCircle,
  faQuestionCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  IterableSubscribeService,
  SegmentTrackingService,
  XccEnvironment,
  XgritApiService,
} from '@xcc-client/services';
import { PaymentContinueService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/payment-continue.service';
import type { SuccessResponse, XccConfig } from '@xcc-models';
import { Brand, CompanyName } from '@xcc-models';
import type { Observable } from 'rxjs';
import { Subject, combineLatest, map, take, takeUntil } from 'rxjs';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { XgritPurchaseService } from '../../xcc-continue-panel/xcc-continue-panel/xgrit-purchase.service';
import { XccStudentAccountPanelService } from './xcc-student-account-panel.service';

@Component({
  selector: 'xcc-student-account-panel',
  templateUrl: './xcc-student-account-panel.component.html',
})
export class XccStudentAccountPanelComponent implements OnInit, OnDestroy {
  faQuestionCircle = faQuestionCircle;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  readonly emailInfoMessage: string;
  readonly xccConfig: Observable<XccConfig>;
  private readonly ngUnsubscribe = new Subject<void>();
  private xccConfig_: XccConfig;
  private lastStudenEmail: string;
  userExistInfoText = 'Your course will be added to your existing account.';
  private couponsAdded = [];
  private productsAdded = [];
  public dupCourseMessage: string;
  faInfoCircle = faInfoCircle;
  faArrowRight = faArrowRight;

  constructor(
    readonly studentAccountPanelService: XccStudentAccountPanelService,
    private readonly route: ActivatedRoute,
    private readonly iterableService: IterableSubscribeService,
    private readonly continuePayService: PaymentContinueService,
    private readonly xgritApiService: XgritApiService,
    private readonly cartService: ShoppingCartService,
    private readonly segmentService: SegmentTrackingService,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly xgritPurchaseService: XgritPurchaseService,
  ) {
    this.xccConfig = this.route.data.pipe(map((data: { xccConfig: XccConfig }) => data.xccConfig));
    this.continuePayService.setUseParent(false);
    this.emailInfoMessage = `Your confidentiality is important to us so you will only receive information directly from ${this.setBrandName(
      this.xccEnv.brand.toUpperCase(),
    )}. Don't find the information helpful? No worries, you can unsubscribe at anytime.`;

    this.xgritPurchaseService.isDupePurchase.subscribe((isDupe) => {
      this.dupCourseMessage = isDupe ? this.xgritPurchaseService.overrideDupErrorMessage() : '';
    });
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: { xccConfig: XccConfig }) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);

    combineLatest([
      this.cartService.conditionalCouponDiscounts$,
      this.cartService.nonConditionalCouponDiscounts$,
      this.cartService.bundleCouponDiscount$,
      this.cartService.additionalCouponDiscount$,
    ]).subscribe(([conditional, nonConditional, bundle, additional]) => {
      this.couponsAdded = [...conditional, ...nonConditional, ...(bundle ? [bundle] : []), ...additional];
    });

    this.cartService.productsArray.subscribe((products) => {
      this.productsAdded = products
        .filter((product) => product.productId !== undefined)
        .map((product) => product.productId);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.formGroup.reset();
  }

  onBlurStudentField = () => {
    const firstName = (this.formGroup.get('firstName').value as string)
      ? (this.formGroup.get('firstName').value as string).trim()
      : '';
    const lastName = (this.formGroup.get('lastName')?.value as string)
      ? (this.formGroup.get('lastName')?.value as string).trim()
      : '';
    const emailField = this.formGroup.get('email');

    if (emailField.valid) {
      this.abandonedCartEvents(emailField.value as string, firstName, lastName, false);
    }
  };

  onBlurStudentEmail = () => {
    const firstName = (this.formGroup.get('firstName').value as string)
      ? (this.formGroup.get('firstName').value as string).trim()
      : '';
    const lastName = (this.formGroup.get('lastName')?.value as string)
      ? (this.formGroup.get('lastName')?.value as string).trim()
      : '';
    const emailField = this.formGroup.get('email');

    if (emailField.valid) {
      this.abandonedCartEvents(emailField.value as string, firstName, lastName, false);
      if (this.lastStudenEmail !== emailField.value) {
        this.checkIfUsersExists(emailField.value as string);
        this.lastStudenEmail = emailField.value as string;
      }
    }
  };

  checkIfUsersExists = (username: string) => {
    const params = { username, brandId: this.xccEnv.brand.toUpperCase() };
    this.xgritApiService.verifyEmail(params).subscribe((response: SuccessResponse) => {
      this.studentAccountPanelService.updateForm(response?.data?.verifyEmail);
    });
  };

  get webAppUrl(): string {
    return this.xccEnv.xgritWebAppUrl;
  }

  get shouldShowInfoIcon(): boolean {
    for (const wizardStep of this.xccConfig_.pageConfig.wizardSteps) {
      if (wizardStep.label === 'Purchase') {
        for (const wizardStepComponent of wizardStep.components) {
          if (wizardStepComponent.className === 'XccStudentAccountPanelComponent') {
            return wizardStepComponent.data?.showInfoIcon;
          }
        }
      }
    }
    return this.xccEnv.brand.toUpperCase() !== Brand.AARP;
  }

  get shouldShowPrivacyPolicy(): boolean {
    return this.xccEnv.brand.toUpperCase() !== Brand.AARP;
  }

  get formGroup(): FormGroup {
    return this.studentAccountPanelService.formGroup;
  }

  get showError(): Observable<boolean> {
    return this.studentAccountPanelService.showError;
  }

  get userExists(): boolean {
    return this.studentAccountPanelService.userExists;
  }

  get passwordNotMatchMessage(): string {
    return this.xccEnv.passwordConfig.passwordNotMatchMessage;
  }

  get showLastNameOnAccountForm(): boolean {
    return this.xccConfig_.pageConfig.showLastNameOnAccountForm ?? false;
  }

  get firstNameLabel(): string {
    return this.xccConfig_.pageConfig.hideStudentLabelOnAccountForm ? 'First Name' : 'Student First Name';
  }

  get lastNameLabel(): string {
    return this.xccConfig_.pageConfig.hideStudentLabelOnAccountForm ? 'Last Name' : 'Student Last Name';
  }

  get emailAddressLabel(): string {
    return this.xccConfig_.pageConfig.hideStudentLabelOnAccountForm ? 'Email Address' : 'Student Email Address';
  }

  get confirmEmailAddressLabel(): string {
    return this.xccConfig_.pageConfig.hideStudentLabelOnAccountForm
      ? 'Confirm Email Address'
      : 'Confirm Student Email Address';
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.xccConfig_ = xccConfig;
    this.studentAccountPanelService.createForm(this.showLastNameOnAccountForm);
  };

  private setBrandName = (brand: string): CompanyName => {
    const brandMap = {
      [Brand.IDS]: CompanyName.IDS,
      [Brand.DEC]: CompanyName.DEC,
      [Brand.AA]: CompanyName.AA,
      [Brand.ACE]: CompanyName.ACE,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return brandMap[brand as Brand] || CompanyName.ACE;
  };

  abandonedCartEvents(email: string, firstName: string, lastName: string, isParent: boolean): void {
    const fields = {
      firstName,
      lastName,
      email: email,
      courseState: this.xccConfig_.productConfig.state,
      coupons: this.couponsAdded,
      products: this.productsAdded,
      productId: this.xccConfig_.productConfig.productId,
      productTitle: this.xccConfig_.productConfig.productTitle,
      customerPrice: this.xccConfig_.productConfig.customerPrice,
      strikePrice: this.xccConfig_.productConfig.strikePrice,
      segment: this.xccConfig_.productConfig.segment,
      isParent: isParent,
    };
    this.iterableService.subscribeUser(fields).pipe(take(1)).subscribe();
    this.segmentService.callIdentifyMethod(null, {
      created_date: new Date().toISOString(),
      email: email,
      first_name: firstName,
    });
  }
}
