<xcc-card-container xccDupeProduct>
  <div content data-test="conditionalDiscountCardContent">
    <div class="flex flex-col">
      <div class="top-content">
        <div class="text-lg text-center mb-4 font-bold">
          <h3 class="mb-0">SPECIAL OFFER</h3>
        </div>

        <div class="text-2xl text-center primary-text font-bold mb-4">
          <h3 class="mb-0">Get One FREE Month of Allstate Roadside</h3>
        </div>

        <div class="text-lg text-center mb-6">Just $5/month after your free trial. Cancel anytime.</div>

        <div class="flex flex-col pl-4 mb-4 xs:mb-8">
          <p class="mb-4 text-xl">
            Keep your whole family safe roadside today for only $5/month through Allstate Roadside Assistance. The other guys charge up to $39 per family member per year.
          </p>
          <p class="mb-4 text-xl">Service never affects insurance rates like other providers</p>
          <p class="mb-4 text-xl">Track your rescue driver online</p>
          <p class="text-xl">Services include: Flat tire, battery, towing fuel delivery, lockout, and much more!</p>
        </div>
      </div>

      <div class="xs:mt-8 sm:mt-0 flex flex-col-reverse xs:flex-row items-center justify-center">
        <div>
          <button
            (click)="onDeclineButtonClicked()"
            class="decline-button cta-button-tertiary !no-underline"
            type="button"
            data-test="declineOfferButton"
          >
            <span class="rsa-decline font-extrabold underline underline-offset-4 decoration-2	xs:mr-[63px] primary-text whitespace-nowrap"
              >No Thanks</span
            >
          </button>
        </div>

        <div class="mb-4 xs:mb-0 w-full xs:w-[281px]">
          <button
            (click)="onClaimButtonClicked()"
            class="w-full claim-button font-extrabold whitespace-nowrap"
            type="button"
            data-test="claimOfferButton"
          >
            Claim Offer
          </button>
        </div>
      </div>
    </div>
  </div>
</xcc-card-container>
