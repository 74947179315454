import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PriceDisplayModule } from '@xcc-client/services';

import { ComponentsModule } from '@xcc-client/shared/components/components.module';
import { NewPriceDisplayModule } from '@xcc-client/shared/components/display-price/display-price.module';
import { XccDialogModule } from '@xcc-client/shared/components/xcc-dialog/xcc-dialog.module';
import { DupeProductModule } from '@xcc-client/shared/directives/dupe-product/dupe-product.module';
import { NotesComponent } from './additional-notes/notes.component';
import { RsaCheckboxOfferComponent } from './rsa-checkbox-offer/rsa-checkbox-offer.component';
import { UpsellAddonItemComponent } from './upsell-add-ons/upsell-addon-item/upsell-addon-item.component';
import { UpsellAddOnsComponent } from './upsell-add-ons/upsell-addons.component';
import { UpsellAddOnsService } from './upsell-add-ons/upsell-addons.service';
import { UpsellBundleComponent } from './upsell-bundle/upsell-bundle.component';
import { UpsellCertificateDeliveryComponent } from './upsell-certificate-delivery/upsell-certificate-delivery.component';
import { XccUpsellOptionsComponent } from './xcc-upsell-options/xcc-upsell-options/xcc-upsell-options.component';

@NgModule({
  declarations: [
    NotesComponent,
    UpsellAddOnsComponent,
    UpsellCertificateDeliveryComponent,
    XccUpsellOptionsComponent,
    UpsellAddonItemComponent,
    UpsellBundleComponent,
    RsaCheckboxOfferComponent,
  ],
  providers: [UpsellAddOnsService],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    PriceDisplayModule,
    NewPriceDisplayModule,
    FontAwesomeModule,
    XccDialogModule,
    DupeProductModule,
  ],
  exports: [NotesComponent],
})
export class XccUpsellPanelModule {}
