import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComponentsModule } from '../components.module';
import { XccPasswordChangeComponent } from './xcc-password-change/xcc-password-change.component';
import { XccPasswordChangeService } from './xcc-password-change/xcc-password-change.service';

@NgModule({
  declarations: [XccPasswordChangeComponent],
  imports: [CommonModule, ReactiveFormsModule, ComponentsModule, FontAwesomeModule],
  providers: [XccPasswordChangeService],
})
export class XccPasswordChangeModule {}
