<section [class]="extendLayoutClasses" class="relative rounded-md bg-white max-w-[893px] m-auto" data-test="cardContainer">
  <!-- Toolbar Content-->
  <ng-content select="[toolbar]"></ng-content>
  <section [class]="extendContainerClasses" class="mb-6 px-6 py-10 xs:px-10">
    <!-- Default Content-->
    <ng-content></ng-content>
    <!-- Heading Content-->
    <ng-content select="[heading]"></ng-content>
    <!-- Body Content -->
    <ng-content select="[content]"></ng-content>
    <!-- Footer Content -->
    <ng-content select="[footer]"></ng-content>
  </section>
</section>
