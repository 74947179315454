export enum Brand {
  IDS = 'IDS',
  DEC = 'DEC',
  AA = 'AA',
  ACE = 'ACE',
  AARP = 'AARP',
  PA = 'PA',
  INS = 'INS',
  MTG = 'MTG',
}

export enum CompanyName {
  IDS = ' I Drive Safely',
  DEC = 'DriversEd',
  ACE = 'Aceable',
  AA = 'AceableAgent',
  AARP = 'AARP',
  PA = 'PrepAgent',
  INS = 'Aceable Insurance',
  MTG = 'Aceable Mortgage',
}

export enum AppTypeName {
  DD = 'DD',
  DE = 'DE',
  RE = 'RE',
  INS = 'INS',
  MTG = 'MTG',
}
