import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DupeProductModule } from '@xcc-client/shared/directives/dupe-product/dupe-product.module';
import { CourseNotesComponent } from './course-notes.component';

@NgModule({
  declarations: [CourseNotesComponent],
  exports: [CourseNotesComponent],
  imports: [CommonModule, DupeProductModule],
})
export class CourseNotesModule {}
