import { Component, Input } from '@angular/core';

@Component({
  selector: 'xcc-card-container',
  templateUrl: './card-container.component.html',
})
export class CardContainerComponent {
  @Input() extendContainerClasses: string;
  @Input() extendLayoutClasses: string;
}
