<xcc-card-container xccDupeProduct>
  <form [formGroup]="formGroup" id="xcc-payment-form" content>
    <h3 class="panel-title xcc-header-spacing">Terms & Conditions</h3>

    <div *ngIf="data?.strictTerms" class="justify-center items-center mb-5">
      <p
        *ngIf="readTermsCheckoutErrorShow"
        class="text-base leading-6 font-normal bg-red-error-bg p-2 text-red-error-text w-full terms-error"
      >
        You must read our Terms & Conditions
      </p>
      <label
        for="type_terms_checkout"
        class="text-base leading-6 font-normal text-dark cursor-pointer mb-6 inline-block"
      >
        <ng-container *ngIf="!data?.strictTerms.labelHtml">
          Type <span class="font-bold">'I Agree'</span> to accept our
          <xcc-dialog data-test="view-terms-trigger" *ngIf="showDialog" buttonType="link" dialogCTA="Terms & Conditions"
            dialogKey="tandc" (affirmRead)="readTerms()"></xcc-dialog>
          {{ data?.strictTerms.label }}
          <a *ngIf="data?.strictTerms.label || data?.strictTerms.labelHtml" [class]="data?.strictTerms.cssAnchorClasses"
            [href]="strictTermsUrl" target="_blank" rel="noopener" (click)="readTerms()">
            <ng-container>{{ data?.strictTerms.anchorText }}</ng-container>
          </a>
        </ng-container>
        <span *ngIf="data?.strictTerms.labelHtml" [innerHTML]="data?.strictTerms.labelHtml | safeHtml"></span>
        <span *ngIf="data?.strictTerms.label">.</span>
      </label>
      <input
        formControlName="type_terms_checkout"
        id="type_terms_checkout"
        placeholder="I Agree"
        type="text"
        class="text-grey-input outline-none rounded-md bg-grey-100 border border-grey-500 pl-3 pr-8 py-3.5 focus:input-hover w-full mb-0"
        [ngClass]="{'!input-error input-error-text': typeTermsCheckoutErrorShow}"
      />
      <p *ngIf="typeTermsCheckoutErrorShow" class="!input-error-text error-input h-5 !mb-0 mt-1">
        You must type ‘I Agree’
      </p>
    </div>

    <div *ngFor="let check of data?.checkboxes" class="justify-center mb-5 relative gap-3 flex items-start flex-col">
      <p
        *ngIf="
          formGroup.get(check.formControlName).invalid &&
          (formGroup.get(check.formControlName).dirty || formGroup.get(check.formControlName).touched)
        "
        [class]="check.cssErrorClasses"
      >
        {{ check.errorMessage }}
      </p>
      <div class="flex gap-2">
        <input [id]="check.formControlName" [formControlName]="check.formControlName" [class]="check.cssClasses"
          type="checkbox" />
        <label *ngIf="!check?.labelHtml" [for]="check.formControlName" [class]="check.cssLabelClasses">
          {{ checkboxLabel(check?.label) }}
          <a *ngIf="check?.anchor" [class]="check?.anchor.cssClasses" [target]="check?.anchor.target" [href]="termsUrl"
            rel="noopener">
            {{ check?.anchor.text }}
          </a>
          <xcc-dialog *ngIf="check?.dialog" [class]="check?.dialog.cssClasses" [buttonType]="check?.dialog.buttonType"
            [dialogCTA]="check?.dialog.dialogCTA" [productTitle]="check?.dialog.dialogTitle"
            [productContent]="check?.dialog.dialogContent" />
          </label>
        <label *ngIf="check?.labelHtml && !check?.label" [innerHTML]="termsLabel(check?.labelHtml)"
          [for]="check.formControlName" [class]="check.cssLabelClasses"></label>
        </div>
    </div>
    <!-- Show error message when shopping cart contains more than 10 products -->
    <div *ngIf="shouldBlockPurchase" class="text-base leading-6 font-normal bg-red-error-bg text-red-error-text p-2 mb-4 rounded">
      You have more than 10 products in your cart, so the purchase cannot be completed.
    </div>

    <button
      (click)="purchaseClick()"
      [disabled]="(isDone | async) || (isLoading | async) || shouldBlockPurchase"
      class="cta-button hover:cta-button-hover disabled:cta-button-disabled w-full mb-5"
    >
      {{paymentButtonLabel}}
    </button>

    <p class="text-xs text-center text-grey-recaptcha !paragraph-color--dark leading-disclaimer font-normal m-0">
      This site is protected by reCAPTCHA Enterprise and the Google
      <a
        class="text-xs text-center text-link leading-disclaimer font-normal"
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noopener"
      >
        Privacy Policy
      </a>
      and
      <a
        class="text-xs text-center text-link leading-disclaimer font-normal"
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noopener"
      >
        Terms of Service
      </a>
      apply.
    </p>
  </form>
</xcc-card-container>
