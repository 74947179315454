import type { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { PaymentFormValidation } from '@xcc-models';
import type { Observable } from 'rxjs';
import { map, of } from 'rxjs';
import { StripePaymentElementService } from '../stripe-payment-element/stripe-payment-element.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentFormService {
  private readonly paymentFormGroup: FormGroup;
  private readonly isValid$: Observable<boolean> = of(false);

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly stripePaymentElementService: StripePaymentElementService,
  ) {
    this.paymentFormGroup = this.createFormGroup();
    // TODO Check if it is no longer needed
    const paymentFormGroupStatusChanges = this.paymentFormGroup.statusChanges.pipe(
      map((status) => {
        return status !== PaymentFormValidation.INVALID;
      }),
    );
  }

  init(): void {
    this.stripePaymentElementService.clearStatusObservables();
  }

  /**
   * Getter for the formGroup, providing access to the payment form group.
   * @returns {FormGroup} The form group representing the payment form.
   */
  get formGroup(): FormGroup {
    return this.paymentFormGroup;
  }

  /**
   * Getter for the isValid observable, providing access to the validity status of the payment.
   * @returns {Observable<boolean>} An observable representing the validity status of the payment.
   */
  get isValid(): Observable<boolean> {
    return this.isValid$;
  }
  /**
   * Enables the payment form by enabling the form group.
   * @returns {void}
   */
  enableForm(): void {
    this.paymentFormGroup.enable();
  }

  /**
   * Disables the payment form by disabling the form group.
   * @returns {void}
   */
  disableForm(): void {
    this.paymentFormGroup.disable();
  }

  /**
   * Creates a form group with a paymentElement form control.
   * @returns {FormGroup} The created form group.
   */

  private createFormGroup() {
    // Define the form control for paymentElement
    const paymentElementControl = ['', [Validators.required, Validators.requiredTrue]];

    // Create the form group with the paymentElement form control
    const paymentForm = { paymentElement: paymentElementControl };

    // Return the form group
    return this.formBuilder.group(paymentForm);
  }

  validate(): void {
    this.stripePaymentElementService.validate();
    this.paymentFormGroup.markAllAsTouched();
    const emitValue = this.paymentFormGroup.valid ? PaymentFormValidation.VALID : PaymentFormValidation.INVALID;

    (this.paymentFormGroup.statusChanges as EventEmitter<string>).emit(emitValue);
  }

  /**
   * Scrolls to the first invalid form element or a specific error element within the payment form.
   * If found, scrolls smoothly to bring it into view.
   * @returns {void}
   */
  goToError(): void {
    // Get the form element that is invalid or the card error wrapper
    const formElement =
      document.getElementById('payment-form').querySelector('.ng-invalid') ||
      document.getElementById('card-error-wrapper');

    // If a valid form element is found, scroll to it smoothly
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
