import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'xcc-account-error-message',
  templateUrl: './account-error-message.component.html',
})
export class AccountErrorMessageComponent {
  @Input() message: string;
  @Input() icon: IconDefinition;
  @Input() extendClass?: string;
}
