import { HttpClient } from '@angular/common/http';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { IdGeneratorService, SegmentTrackingService, WizardStepsService, XccEnvironment } from '@xcc-client/services';
import type { Product, XccConfig } from '@xcc-models';
import { Brand, CouponDiscountType, UidList } from '@xcc-models';
import { CouponService } from './coupon/coupon.service';
import { ShoppingCartService } from './shopping-cart.service';

const DISCOUNT_PRODUCTS_UID = ['discount', 'rsa-discount'];
const COUPON_PRODUCTS_UID = ['coupon', 'voucher']; // Used to segment the coupon as product
const CDI_PRODUCT_UID = ['noop'];

@Component({
  selector: 'xcc-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  private totalPriceDollars_: Observable<number>;
  private sisterProductSum_: Observable<number>;
  private sisterProductSubtotal_: Observable<number>;
  private freeProducts_: Product[];
  private intersectionObserver: IntersectionObserver;
  private mainProduct_: Product;
  public isBundleSelected: boolean;
  private ngUnsubscribe = new Subject<void>();
  private couponProducts_: Product; // Store coupon
  public remainingProducts_: Product[];
  private specialDiscount_: Product;
  private xccConfig_: XccConfig;
  private hasReferralCoupon: boolean;
  activeStepIndex: number;
  hasCoupon = false;
  uhcDiscount = false;
  showCouponForm = false;
  UidList = UidList;
  addCouponCode: boolean;
  public svgIcon: any;
  faCircleNotch = faCircleNotch;

  constructor(
    public readonly shoppingCartService: ShoppingCartService,
    public readonly wizardStepsService: WizardStepsService,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly idService: IdGeneratorService,
    private readonly route: ActivatedRoute,
    private readonly segmentTracking: SegmentTrackingService,
    private readonly couponService: CouponService,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  /**
   * Gets the settings for the coupon component.
   * @returns An object containing the settings for the coupon component.
   */
  get couponComponentSettings(): { shouldBeExpanded: boolean; label: string } {
    return {
      shouldBeExpanded: this.showCouponForm || this.hasCoupon,
      label: this.xccConfig_?.pageConfig?.couponFieldLabel ?? 'Add promotion code',
    };
  }

  /**
   * Determines if the shopping cart contains multiple main products.
   *
   * @returns {boolean} - True if the shopping cart has multiple main products, false otherwise.
   */
  get isMultiProduct(): boolean {
    return this.xccConfig_.productConfig?.isMultiMainProductIds;
  }

  /**
   * Determines whether the purchase should be blocked based on the product configuration.
   *
   * @returns {boolean} - Returns `true` if the purchase should be blocked, otherwise `false`.
   */
  get shouldBlockPurchase(): boolean {
    return this.xccConfig_.productConfig.shouldBlockPurchase;
  }

  get loadingState(): boolean {
    return this.shoppingCartService.shoppingCartIsLoading;
  }
  /**
   * Interceptor to get the coupon information if it is added to be used
   * on the shopping cart component
   * @returns {Product} coupon formed as product
   */
  get couponProducts(): Readonly<Product> {
    return this.couponProducts_;
  }

  get cartCouponAdded(): boolean {
    for (const product of this.remainingProducts) {
      if (product.xgritData?.couponCodeList?.length > 0) {
        return true;
      }
    }
    if (this.mainProduct.xgritData?.couponCodeList?.length > 0) {
      return true;
    }
    return false;
  }

  get freeProducts(): ReadonlyArray<Product> {
    return this.freeProducts_;
  }

  get mainProduct(): Readonly<Product> {
    return this.mainProduct_;
  }

  get remainingProducts(): ReadonlyArray<Product> {
    return this.remainingProducts_;
  }

  get specialDiscount(): Readonly<Product> {
    return this.specialDiscount_;
  }

  get totalPriceDollars(): Observable<number> {
    return this.totalPriceDollars_;
  }

  get totalSisterProducts(): Observable<number> {
    return this.sisterProductSum_;
  }

  get subtotal(): Observable<number> {
    return this.sisterProductSubtotal_;
  }

  get guarantee(): string {
    return this.xccConfig_.pageConfig?.rhsConfig.trustmarkConfig.guarantee ?? '';
  }

  get guaranteeCopy(): string {
    return this.xccConfig_.pageConfig?.rhsConfig.trustmarkConfig.guaranteeCopy ?? '';
  }

  get displayGuarantee(): boolean {
    return this.xccConfig_.pageConfig?.hideGuarantee ?? true;
  }

  // TODO: Implement better more reliable solution
  get hasCdiFirstStepOffer(): boolean {
    return this.xccConfig_.pageConfig?.wizardSteps?.some(({ label }) => label === 'Limited Time Offer');
  }

  // TODO: Implement better more reliable solution
  get cdiFirstStepOfferIndex(): number {
    return this.xccConfig_.pageConfig.wizardSteps.findIndex(({ label }) => label === 'Limited Time Offer');
  }

  get triggerRsa(): boolean {
    return this.xccConfig_.pageConfig.triggerRsa;
  }

  get dialogCTA() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.xccConfig_.brand == Brand.IDS ? '100% Satisfaction Guarantee' : this.svgIcon;
  }

  get multiProducts(): Product[] {
    return this.xccConfig_.productConfig?.multiProducts ?? [];
  }

  ngOnInit() {
    /**
     * *Default Coupon Discount*
     * Triggered when default coupon discount is added into the storage array
     * observer, if new value is added we should trigger the price update service.
     *
     * This could be triggered when user claims RSA.
     */
    this.shoppingCartService.conditionalCouponDiscounts$.subscribe(() => {
      this.shoppingCartService.updateProductPrices();
    });

    /**
     * *Additional Coupon Discount*
     * Triggered when additional coupon discount is added into the storage array
     * observer, if new value is added we should trigger the price update service
     */
    this.shoppingCartService.additionalCouponDiscount$.subscribe(() => {
      this.shoppingCartService.updateProductPrices();
    });

    if (window && 'IntersectionObserver' in window) {
      const intersectionSensor = document.querySelector('#intersection-sensor');
      this.intersectionObserver = this.createIntersectionObserver();
      if (intersectionSensor) this.intersectionObserver.observe(intersectionSensor);
    }
    this.idService.generateId();

    this.wizardStepsService.indexChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectedIndex) => (this.activeStepIndex = selectedIndex));

    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig as XccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((config) => (this.xccConfig_ = config));

    if ((!this.xccConfig_.pageConfig?.hideGuarantee ?? false) && this.xccConfig_.brand !== Brand.IDS) {
      this.httpClient.get('assets/svg/guarantee.svg', { responseType: 'text' }).subscribe((value) => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
      });
    }

    this.shoppingCartService.productsAsArray.pipe(takeUntil(this.ngUnsubscribe)).subscribe((products) => {
      this.onProductsChanged(products);
      this.cdr.detectChanges();
    });

    this.couponService.hasCoupon.pipe(takeUntil(this.ngUnsubscribe)).subscribe((hasCoupon) => {
      this.hasCoupon = hasCoupon;
    });

    this.shoppingCartService.uhcDiscount.subscribe((uhcDiscount) => {
      this.uhcDiscount = uhcDiscount;
    });

    this.totalPriceDollars_ = this.shoppingCartService.totalPriceDollarsChanged;
    this.sisterProductSum_ = this.shoppingCartService.sisterProductSumChanged;
    this.sisterProductSubtotal_ = this.shoppingCartService.sisterProductSubtotalChanged;

    this.showCouponForm = this.xccConfig_.pageConfig?.couponFieldExpanded ?? false;

    this.shoppingCartService.setSegment(this.xccConfig_.productConfig?.segment || '');
  }

  ngOnDestroy(): void {
    // Disconnect our intersection observer if we created one
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleCoupon() {
    this.addCouponCode = !this.addCouponCode;
    if (!this.addCouponCode) {
      this.couponService.removeCouponFromCart();
    }
  }

  notQueryParamCoupon(couponCode: string) {
    const params = this.route.snapshot.queryParams;
    if (params.coupon) {
      return params.coupon !== couponCode;
    }
    return true;
  }

  private createIntersectionObserver(): IntersectionObserver {
    const options = {
      root: null,
      rootMargin: '0px', // Height of footer
      threshold: 0, // End of shopping cart
    };
    return new IntersectionObserver((entries) => this.intersectionObservableCallback(entries), options);
  }

  private intersectionObservableCallback(entries: IntersectionObserverEntry[]): void {
    const fixedClass = 'cart-is-fixed';
    // We only have 1 shopping cart so it will always be the first entry
    const entry = entries[0];

    if (entry.target !== null) {
      if (entry.isIntersecting === false) {
        // When shopping cart is NOT in the viewport add the class
        entry.target.classList.add(fixedClass);
      } else {
        // When shopping cart IS in the viewport remove the class
        entry.target.classList.remove(fixedClass);
      }
    }
  }

  public removeCoupon(code: string): void {
    this.couponService.removeCouponFromCart(code);
  }

  private onProductsChanged(allProducts: Product[]): void {
    if (allProducts == null) {
      return;
    }
    this.specialDiscount_ = undefined;
    this.couponProducts_ = undefined; // Set to undefined in case we remove the coupon
    const allOtherProducts: Product[] = [];
    const zeroDollarsProducts: Product[] = [];
    const productIdQueryparam = this.route.snapshot.queryParamMap.get('productId');

    for (const curProd of allProducts) {
      // The default main product should be the URL's productId param
      if (curProd.productId === productIdQueryparam || curProd.replaceCartMainProduct === true) {
        this.mainProduct_ = curProd;
        continue;
      }

      if (curProd.customerPrice === 0) {
        zeroDollarsProducts.push(curProd);
        continue;
      }

      if (DISCOUNT_PRODUCTS_UID.includes(curProd.uid)) {
        this.specialDiscount_ = curProd;
        continue;
      }

      // If curProd includes 'coupon', add to this.couponProducts_ to be shown in the shopping cart
      if (COUPON_PRODUCTS_UID.includes(curProd.uid)) {
        // Coupon with percentage discount
        if (curProd.discountType.includes(CouponDiscountType.offPercent)) {
          const priceWithoutCoupon = allProducts
            .filter((product) => {
              return (
                !COUPON_PRODUCTS_UID.includes(product.uid) &&
                !CDI_PRODUCT_UID.includes(product.uid) &&
                !isNaN(product?.customerPrice as number)
              );
            })
            .reduce((prev, product) => prev + Number(product.customerPrice), 0);
          curProd.customerPrice = -(priceWithoutCoupon * curProd.offPercent);
        }

        this.couponProducts_ = curProd;
        continue;
      }

      allOtherProducts.push(curProd);
    }
    this.remainingProducts_ = allOtherProducts;
    this.freeProducts_ = zeroDollarsProducts;
    this.couponService.setTriggerRsa(this.xccConfig_.pageConfig?.triggerRsa);
  }
}
