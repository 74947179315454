import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DupeProductModule } from '../directives/dupe-product/dupe-product.module';
import { CardContainerComponent } from './card-container/card-container.component';
import { XCCInputComponent } from './input/xcc-input.component';
import { StripePaymentElementComponent } from './payment/stripe-payment-element/stripe-payment-element.component';
import { StripePaymentFormComponent } from './payment/stripe-payment-form/stripe-payment-form.component';

@NgModule({
  declarations: [CardContainerComponent, XCCInputComponent, StripePaymentElementComponent, StripePaymentFormComponent],
  exports: [CardContainerComponent, XCCInputComponent],
  imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule, DupeProductModule],
})
export class ComponentsModule {}
