import { Injectable } from '@angular/core';
import { Brand } from '@xcc-models';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  subverticalACEBrands = [Brand.AA, Brand.PA, Brand.INS, Brand.MTG];
  getBrandById(brandId: string): string {
    if (this.subverticalACEBrands.includes(brandId as Brand)) {
      return Brand.ACE;
    } else {
      return brandId;
    }
  }

  getBrandByIdForIterable(brandId: string): string {
    if (brandId === Brand.PA) {
      return Brand.AA;
    } else {
      return brandId;
    }
  }
}
