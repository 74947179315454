import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DupeProductModule } from '@xcc-client/shared/directives/dupe-product/dupe-product.module';
import { ComponentsModule } from '../components.module';
import { ErrorMessagesModule } from '../error-messages/error-messages.module';
import { XccParentAccountPanelComponent } from './xcc-parent-account-panel/xcc-parent-account-panel.component';
import { XccParentAccountPanelService } from './xcc-parent-account-panel/xcc-parent-account-panel.service';

@NgModule({
  declarations: [XccParentAccountPanelComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ComponentsModule,
    DupeProductModule,
    ErrorMessagesModule,
  ],
  providers: [XccParentAccountPanelService],
})
export class XccParentAccountPanelModule {}
